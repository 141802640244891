.component-cities-list {
  overflow: hidden;
  font-size: 0;
  margin-left: -11px;
  margin-right: -11px;
}
.component-cities-list > li {
  width: calc((100% - 72px) / 3);
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  border: 1px solid #DDE3E8;
  background: #fff;
  margin: 11px;
  text-align: left;
}
.component-cities-list > li a {
  display: block;
  overflow: hidden;
  padding: 22px 25px 0 25px;
  height: 100px;
  box-sizing: border-box;
}
.component-cities-list > li img {
  float: right;
  width: 60px;
  margin-top: 1px;
}
.component-cities-list > li.capital a {
  background: #ffefce;
}
.component-cities-list > li.capital .country-region-city-name {
  color: #e08400;
}
.component-cities-list .max-cities-link a {
  font-size: 0;
  color: #1C5EA0;
  text-decoration: none;
  display: block;
  height: 98px;
  padding: 0 0 0 72px !important;
  background: url(/css/img/all_cities.svg) 26px center / 21px 21px no-repeat;
}
.component-cities-list .max-cities-link a:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}